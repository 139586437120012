//Polyfill
import "core-js";
import jQuery from "jquery";
const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)
//Can't resolve 'regenerator-runtime/runtime'が表示されたとき(async awaitを使う場合など)
//import  regeneratorRuntime  from  "regenerator-runtime" ;

//bootstrapのmodalを読み込み
import "/node_modules/bootstrap/js/dist/modal";

$(function() {
    // スマホ時 メニュー開閉
    var openBtn = $("#js-header-btn");
    var closeBtn = $(".close");
    var gNav = $(".js-gnav");
    openBtn.click(function(e) {
        e.preventDefault();
        if ($(this).hasClass("open")) {
            gNav.removeClass("open");
            $(this).removeClass("open");
        } else {
            gNav.addClass("open");
            $(this).addClass("open");
        }
    });
    $(closeBtn).on('click', function(){
        $('#js-gnav,.js-gnav').removeClass('open');
    });
});